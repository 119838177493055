export const GET_RESPONSE = "GET_RESPONSE";
export const START_CHAT = "START_CHAT";
export const CHAT_ERROR = "CHAT_ERROR";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const START_QNA = "START_QNA";
export const QNA_ERROR = "QNA_ERROR";
export const SEND_ANSWER = "SEND_ANSWER";
export const START_VOICE = "START_VOICE";
export const VOICE_ERROR = "VOICE_ERROR";
export const VOICE_SEND = "VOICE_SEND";
export const STOP_TALKING = "STOP_TALKING";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const GET_DATA = "GET_DATA";
export const UI_ERROR = "UI_ERROR";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const RESET_ATTRIBUTES = "RESET_ATTRIBUTES";
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
