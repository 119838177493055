import {
	SHOW_SNACKBAR,
	CLOSE_SNACKBAR,
	GET_DATA,
	UI_ERROR,
	SHOW_MODAL,
	CLOSE_MODAL,
} from "../actions/types";

const initialState = {
	snackbarOpen: false,
	snackbarSeverity: "",
	snackbarMessage: "",
	attributes: [],
	loading: true,
	modalOpen: false,
};

export default (state = initialState, action) => {
	const {
		type,
		severity,
		message,
		modalContent,
		modalDescription,
		isVideo,
		payload,
	} = action;
	switch (type) {
		case SHOW_SNACKBAR:
			return {
				...state,
				snackbarOpen: true,
				snackbarSeverity: severity,
				snackbarMessage: message,
			};
		case CLOSE_SNACKBAR:
			return {
				...state,
				snackbarOpen: false,
				snackbarSeverity: "",
				snackbarMessage: "",
			};
		case SHOW_MODAL:
			return {
				...state,
				modalOpen: true,
				isVideo,
				modalContent,
				modalDescription,
			};
		case CLOSE_MODAL:
			return {
				...state,
				modalOpen: false,
				modalContent: "",
			};
		case GET_DATA:
			return {
				...state,
				...payload,
				loading: false,
			};
		case UI_ERROR:
			return {
				error: payload,
			};
		default:
			return state;
	}
};
