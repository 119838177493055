import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { closeAlert } from "../actions/uiActions";

const AlertMessage = () => {
	const dispatch = useDispatch();
	const uiState = useSelector((state) => state.uiReducer);

	const Alert = (props) => {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	};

	return (
		uiState.snackbarOpen && (
			<Snackbar
				open={uiState.snackbarOpen}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={() => dispatch(closeAlert())}
			>
				<Alert
					severity={uiState.snackbarSeverity}
					onClose={() => dispatch(closeAlert())}
				>
					{uiState.snackbarMessage}
				</Alert>
			</Snackbar>
		)
	);
};

export default AlertMessage;
