import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { getElements } from "./actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { parseHtml } from "./components/helpers";
import LoadingComponent from "./components/LoadingComponent";

const Section = styled.article`
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:nth-child(1) {
		padding-top: 30px;
	}
	& p {
		font-weight: 300;
	}
`;

const Heading = styled.h1`
	margin: 10px 0px;
	font-size: 28px;
	font-weight: 500;
	letter-spacing: 1.5px;
	line-height: 30px;
	text-align: center;
`;

const ModeOption = styled.div`
	${({ theme }) => `
		& h4 {
			width: 100%;
			text-align: center;
			border-bottom: 1px solid ${theme.palette.secondary.main};
			line-height: 0.1em;
			margin: 10px 0 0;
			& span {
				color: ${theme.palette.primary.main};
				font-weight: 500;
				font-size: 20px;
				padding: 0 10px;
				background-color: white;
			}
		}
		& div.content {
			font-size: 16px;
			text-align: justify;
			font-weight: 300;
			border-left: 1px solid ${theme.palette.secondary.main};
			border-right: 1px solid ${theme.palette.secondary.main};
			border-bottom: 1px solid ${theme.palette.secondary.main};
			padding: 15px 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		button {
			color: ${theme.palette.gray.dark};
			margin-top: 8px;
		}
	`}
`;

const ModeChoice = () => {
	const uiState = useSelector((state) => state.uiReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getElements("modeChoice"));
	}, []);

	return (
		<Fragment>
			{!uiState.loading && uiState.title ? (
				<Container maxWidth="md">
					<Section>
						<Heading>
							{parseHtml(uiState.title, { removeOuterP: true })}
						</Heading>
						{parseHtml(uiState.instructions)}
					</Section>
					<Section>
						<Grid container spacing={2} justify="center" alignItems="center">
							{!isMobile && (
								<Grid item xs={12}>
									<ModeOption>
										<h4>
											<span>
												{parseHtml(uiState.voiceSubhead, {
													removeOuterP: true,
												})}
											</span>
										</h4>
										<div className="content">
											{parseHtml(uiState.voiceBody)}
											<Link to="/voice">
												<Button>Continue >></Button>
											</Link>
										</div>
									</ModeOption>
								</Grid>
							)}
							<Grid item xs={12}>
								<ModeOption>
									<h4>
										<span>
											{parseHtml(uiState.chatSubhead, { removeOuterP: true })}
										</span>
									</h4>
									<div className="content">
										{parseHtml(uiState.chatBody)}
										<Link to="/chat">
											<Button>Continue >></Button>
										</Link>
									</div>
								</ModeOption>
							</Grid>
							<Grid item xs={12}>
								<ModeOption>
									<h4>
										<span>
											{parseHtml(uiState.surveySubhead, { removeOuterP: true })}
										</span>
									</h4>
									<div className="content">
										{parseHtml(uiState.surveyBody)}
										<Link to="/survey">
											<Button>Continue >></Button>
										</Link>
									</div>
								</ModeOption>
							</Grid>
						</Grid>
					</Section>
				</Container>
			) : (
				<LoadingComponent />
			)}
		</Fragment>
	);
};

export default ModeChoice;
