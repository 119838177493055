import React, { useState, useRef, useEffect } from "react";
import { GiftedChat, Bubble, Send, Composer } from "react-native-gifted-chat";
import { useDispatch, useSelector } from "react-redux";
import { View } from "react-native";
import shortid from "shortid";
import { saveAs } from "file-saver";
import moment from "moment";
import styled from "styled-components";
import BugReportIcon from "@material-ui/icons/BugReport";
import LoadingComponent from "./components/LoadingComponent";
import Fab from "@material-ui/core/Fab";
import ConversationLayout from "./components/ConversationLayout";
import { startChat, onSend, addAttribute } from "./actions/chatActions";
import { getElements, showAlert, showModal } from "./actions/uiActions";
import { parseHtml, renderVisual } from "./components/helpers";
import { messageMap } from "./messageMap";

const Content = styled.div`
	${({ theme }) => `
		& a {
			color: ${theme.palette.secondary.main};
			&:hover {
				text-decoration: underline;
			}
		}
	`}
`;

const TextChat = () => {
	const chatState = useSelector((state) => state.chatReducer);
	const reduxMessages = useSelector((state) => state.chatReducer.messages);
	const uiState = useSelector((state) => state.uiReducer);
	const dispatch = useDispatch();
	const giftedChat = useRef();
	const [parsedMessages, setParsedMessages] = useState([]);
	const [messageInput, setMessageInput] = useState("");

	useEffect(() => {
		dispatch(startChat());
		dispatch(getElements("modeChoice"));
	}, []);

	useEffect(() => {
		if (!uiState.loading && reduxMessages) {
			let parsed = [];
			for (let messageObj of reduxMessages) {
				if (typeof messageObj.text === "string") {
					parsed.push({
						...messageObj,
						text: parseHtml(messageObj.text, { removeOuterP: true }),
					});
				}
			}
			setParsedMessages(parsed);
		}
	}, [reduxMessages]);

	useEffect(() => {
		if (chatState.error) dispatch(showAlert("error", chatState.error.message));
	}, [chatState]);

	useEffect(() => {
		if (chatState.responseMessageID === messageMap.callToAction) {
			dispatch(
				showModal(reduxMessages[1].text, "A message from tuzag's CEO", true)
			);
		}
	}, [reduxMessages]);

	const newMessageAction = (textToSend) => {
		return dispatch(
			onSend([
				{
					_id: shortid.generate(),
					createdAt: new Date(),
					text: textToSend,
					user: { _id: 1 },
				},
			])
		);
	};

	const handleAttr = (attribute) => {
		dispatch(
			addAttribute({
				aboutTuzagOverviewIntroductionList: attribute.toLowerCase(),
			})
		);
		newMessageAction(attribute);
	};

	const renderMessage = (props) => (
		<Bubble
			{...props}
			wrapperStyle={{
				right: { backgroundColor: "#d93994" },
			}}
			containerStyle={{
				left: { marginBottom: "15px" },
				right: { marginBottom: "15px" },
			}}
			textStyle={{
				left: { textAlign: "left" },
				right: { textAlign: "right" },
			}}
			touchableProps={{
				onPress: () => {
					if (chatState.nextMessageID === messageMap.callToAction) {
						newMessageAction("I'm interested");
					}
				},
			}}
		/>
	);

	const renderSend = (props) => (
		<Send {...props} alwaysShowSend={true} textStyle={{ color: "#d93994" }} />
	);

	const renderComposer = (props) => (
		<Composer
			{...props}
			textInputAutoFocus={true}
			multiline={false}
			textInputStyle={{
				color: "#2f3d47",
				backgroundColor: "#f0f0f0",
				borderRadius: "5px",
				marginLeft: "0px",
				padding: "10px",
			}}
			textInputProps={{
				ref: giftedChat,
				onKeyPress: (evt) => {
					if (evt.charCode === 13) {
						setMessageInput("");
						dispatch(
							onSend([
								{
									_id: shortid.generate(),
									createdAt: new Date(),
									text: evt.target.value,
									user: { _id: 1 },
								},
							])
						);
						setTimeout(() => {
							giftedChat.current.focus();
						}, 100);
					}
				},
			}}
		/>
	);

	const exportFile = () => {
		const text = reduxMessages.reverse().map((message) => {
			return `${
				message.user.name === "TCS" ? "TCS:\r\n" : "User:\r\n"
			}${decodeURIComponent(message.text)}\r\n\r\n`;
		});

		const blob = new Blob(
			[
				`${text.join("")}\r\n\r\nAttributes:\r\n${JSON.stringify(
					chatState.newUserData
				)}`,
			],
			{
				type: "text/plain;charset=utf-8",
			}
		);
		saveAs(
			blob,
			`Transcript and Attrs - ${moment().format("h:mm:ss a l")}.txt`
		);
	};

	return (
		<>
			{!uiState.loading ? (
				<ConversationLayout
					pageTitle="Chat Mode"
					content={
						<div>
							<Content>
								<View style={{ width: "98%", height: 475 }}>
									<GiftedChat
										messages={parsedMessages}
										onSend={(reduxMessages) => {
											dispatch(onSend(reduxMessages));
											setTimeout(() => {
												giftedChat.current.focus();
											}, 100);
										}}
										user={{
											_id: 1,
										}}
										text={messageInput}
										onInputTextChanged={(value) => setMessageInput(value)}
										renderMessage={renderMessage}
										renderComposer={renderComposer}
										renderSend={renderSend}
									/>
								</View>
							</Content>
							<div
								onClick={exportFile}
								style={{
									cursor: "pointer",
									position: "fixed",
									bottom: 125,
									right: 15,
								}}
							>
								<Fab disabled aria-label="export">
									<BugReportIcon />
								</Fab>
							</div>
						</div>
					}
					visual={
						chatState.elementMap &&
						renderVisual(chatState.elementMap.visual, handleAttr)
					}
					instructions={parseHtml(uiState.chatModeInstructions)}
				/>
			) : (
				<LoadingComponent />
			)}
		</>
	);
};

export default TextChat;
