import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import { startVoice, onVoiceSend, addAttribute } from "./actions/voiceActions";
import { getElements, showAlert } from "./actions/uiActions";
import ConversationLayout from "./components/ConversationLayout";
import { OrangeButton, PurpleButton } from "./components/Buttons";
import { parseHtml, renderVisual } from "./components/helpers";
import LoadingComponent from "./components/LoadingComponent";

const CustomContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const InstructionText = styled.p`
	font-weight: 300;
	font-size: 16px;
	margin-bottom: 25px;
`;

const Voice = () => {
	const voiceState = useSelector((state) => state.voiceReducer);
	const dispatch = useDispatch();
	const uiState = useSelector((state) => state.uiReducer);

	useEffect(() => {
		dispatch(startVoice());
		dispatch(getElements("modeChoice"));
	}, []);

	useEffect(() => {
		if (voiceState.error)
			dispatch(showAlert("error", voiceState.error.message));
	}, [voiceState]);

	let recorder;

	const bytesToUrl = (bytes) => {
		const buffer = new Uint8Array(bytes.length);
		buffer.set(new Uint8Array(bytes), 0);
		return URL.createObjectURL(new Blob([buffer], { type: "text/plain" }));
	};

	const startRecording = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				recorder = new MediaRecorder(stream);

				recorder.addEventListener("dataavailable", (e) => {
					dispatch(onVoiceSend(e.data));
				});

				recorder.start();
			})
			.catch((error) => console.error(error));
	};

	const stopRecording = () => {
		recorder.stop();
		recorder.stream.getTracks().forEach((i) => i.stop());
	};

	const handleAttr = (attribute) => {
		dispatch(
			addAttribute({
				aboutTuzagOverviewIntroductionList: attribute.toLowerCase(),
			})
		);
		dispatch(onVoiceSend(attribute));
	};

	return (
		<Fragment>
			{!uiState.loading ? (
				<ConversationLayout
					pageTitle="Voice Mode"
					content={
						<CustomContainer>
							<h1>Voice Mode</h1>
							<InstructionText>
								{parseHtml(uiState.voiceModeInstructions, {
									removeOuterP: true,
								})}
							</InstructionText>
							<audio
								id="jaime-audio"
								src={
									voiceState.talking
										? bytesToUrl(voiceState.audio.data.data)
										: null
								}
								autoPlay
								onEnded={() => dispatch({ type: "STOP_TALKING" })}
								controls
							/>
							<div style={{ marginTop: "15px" }}>
								<PurpleButton
									onClick={startRecording}
									startIcon={<MicIcon />}
									style={{ display: "inline-block", marginRight: "20px" }}
								>
									Start
								</PurpleButton>
								<OrangeButton
									onClick={stopRecording}
									startIcon={<StopIcon />}
									style={{ display: "inline-block" }}
								>
									Stop
								</OrangeButton>
							</div>
						</CustomContainer>
					}
					visual={
						voiceState.elementMap &&
						renderVisual(voiceState.elementMap.visual, handleAttr)
					}
				/>
			) : (
				<LoadingComponent />
			)}
		</Fragment>
	);
};

export default Voice;
