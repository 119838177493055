import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getAttributes, getElements, getSimContent } from "./actions/uiActions";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { OrangeButton } from "./components/Buttons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

const SectionContainer = styled.div`
	${({ theme }) => `
		border: 1px solid ${theme.palette.primary.main};
		padding: 15px 10px;
		min-height: 300px;
		@media (min-width: 600px) {
			min-height: 475px;
		}
	`}
`;

const Paragraph = styled.p`
	margin-bottom: 15px;
`;

const BackButton = styled.div`
	margin-top: -25px;
	padding-bottom: 10px;
`;

const InstructionText = styled.p`
	font-weight: 300;
	font-size: 16px;
`;

const Simulator = () => {
	const uiState = useSelector((state) => state.uiReducer);
	const dispatch = useDispatch();
	let { id } = useParams();
	const [formValues, setFormValues] = useState({});

	useEffect(() => {
		dispatch(getAttributes(id));
		dispatch(getElements(null, id, false));
	}, []);

	useEffect(() => {
		if (uiState.attributes.length) {
			uiState.attributes.map((attribute) => {
				return handleStateChange(attribute.attribute.name);
			});
		}
	}, [uiState.attributes]);

	const handleStateChange = (key, value = "") => {
		return setFormValues((prevState) => ({
			...prevState,
			[key]: value,
		}));
	};

	return (
		<Container maxWidth="lg" style={{ paddingTop: "40px" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<BackButton>
						<Link to="/">
							<Button
								variant="contained"
								color="secondary"
								startIcon={<ArrowBackIcon />}
								onClick={() => window.close()}
							>
								{uiState.backButton}
							</Button>
						</Link>
					</BackButton>
					<InstructionText>{uiState.explanation}</InstructionText>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionContainer>
						<h2>Input</h2>
						{Object.keys(formValues).length &&
							uiState.attributes.map((attribute, index) =>
								attribute.attribute.name.includes("First Name") ? (
									<div>
										<FormControl
											component="fieldset"
											key={index}
											fullWidth={true}
										>
											<TextField
												value={formValues[attribute.attribute.name]}
												label={attribute.attribute.name}
												onChange={(event) =>
													handleStateChange(
														attribute.attribute.name,
														event.target.value
													)
												}
											/>
										</FormControl>
									</div>
								) : (
									<div>
										<FormControl
											component="fieldset"
											key={index}
											fullWidth={true}
										>
											<InputLabel>{attribute.attribute.name}</InputLabel>
											<Select
												value={formValues[attribute.attribute.name]}
												onChange={(event) =>
													handleStateChange(
														attribute.attribute.name,
														event.target.value
													)
												}
											>
												{attribute.attribute.options.map((option) => (
													<MenuItem value={option.name} key={option.name}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
								)
							)}
						<OrangeButton
							onClick={() => dispatch(getSimContent(id, formValues))}
						>
							Test It Out
						</OrangeButton>
					</SectionContainer>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionContainer>
						<h2>Output</h2>
						{uiState.cheeseTown && (
							<div
								style={{
									backgroundImage: `url("${uiState.cheeseTown.background}")`,
									height: "calc(600px - 16px)",
									width: "calc(160px - 16px)",
									padding: "8px",
									margin: "0 auto",
									textAlign: "center",
								}}
							>
								<div style={{ height: "100px", paddingTop: "5px" }}>
									<h2
										style={{
											color: "#9a5b08",
											fontSize: "18px",
											textTransform: "uppercase",
											fontWeight: 700,
											lineHeight: 1.15,
										}}
									>
										{uiState.cheeseTown.callToAction}
									</h2>
								</div>
								<div style={{ height: "80px" }}>
									<img
										src={uiState.cheeseTown.hobby}
										alt="hobby"
										style={{ marginTop: "-15px" }}
									/>
								</div>
								<div style={{ height: "140px" }}>
									<p style={{ fontSize: "14px", color: "#6f4207" }}>
										{uiState.cheeseTown.body}
									</p>
								</div>
								<div
									style={{
										height: "160px",
										position: "relative",
										paddingTop: "8px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<p
										style={{
											fontSize: "14px",
											color: "#9a0808",
											fontStyle: "italic",
											lineHeight: 1.1,
										}}
									>
										{uiState.cheeseTown.topTag}
									</p>
									<div style={{ position: "relative", height: "100px" }}>
										<img
											src={uiState.cheeseTown.state}
											alt="state"
											style={{
												position: "relative",
												zIndex: 1,
												top: "-13px",
												left: "-8px",
											}}
										/>
										<img
											src={uiState.cheeseTown.cheese}
											alt="state"
											style={{
												position: "relative",
												zIndex: 2,
												left: "-5px",
												top: "-130px",
											}}
										/>
									</div>
									<p
										style={{
											fontSize: "14px",
											color: "#9a0808",
											fontStyle: "italic",
											lineHeight: 1.1,
										}}
									>
										{uiState.cheeseTown.bottomTag}
									</p>
								</div>
								<div
									style={{
										height: "95px",
									}}
								>
									<p
										style={{
											color: "#9a5b08",
											fontSize: "14px",
											textTransform: "uppercase",
											fontWeight: 500,
											lineHeight: 1.15,
											paddingTop: "15px",
										}}
									>
										{uiState.cheeseTown.headline}
									</p>
								</div>
							</div>
						)}
						{uiState.thanksgiving && (
							<div style={{ marginTop: "20px" }}>
								<Paragraph>{uiState.thanksgiving}</Paragraph>
							</div>
						)}
						{uiState.stress && (
							<div style={{ marginTop: "20px" }}>
								<Paragraph>{uiState.stress.firstParagraph}</Paragraph>
								<Paragraph>{uiState.stress.secondParagraph}</Paragraph>
								<Paragraph>{uiState.stress.thirdParagraph}</Paragraph>
							</div>
						)}
					</SectionContainer>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Simulator;
