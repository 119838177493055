import { ADD_ATTRIBUTE, START_VOICE, VOICE_ERROR, VOICE_SEND } from "./types";
import API from "../API";
import { sendError } from "./actionHelpers";

export const startVoice = () => async (dispatch, getState) => {
	const state = getState();
	const { newUserData } = state.voiceReducer;

	try {
		const res = await API("/prospectChat/voice/", "POST", {
			start: true,
			userData: newUserData,
			requestedElements: ["visual"],
		});

		dispatch({
			type: START_VOICE,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, VOICE_ERROR));
	}
};

export const onVoiceSend = (voiceRecording) => async (dispatch, getState) => {
	try {
		const state = getState();
		const { nextMessageID, newUserData, prospectID } = state.voiceReducer;

		const formData = new FormData();
		formData.append("file", voiceRecording);
		formData.append("currentMessageID", nextMessageID);
		formData.append("prospectID", prospectID);
		formData.append("userData", JSON.stringify(newUserData));
		formData.append("requestedElements", JSON.stringify(["visual"]));

		const res = await API("/prospectChat/voice/", "POST", null, null, formData);

		dispatch({
			type: VOICE_SEND,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, VOICE_ERROR));
	}
};

export const addAttribute = (attributeObj) => async (dispatch) => {
	return dispatch({
		type: ADD_ATTRIBUTE,
		payload: attributeObj,
	});
};
