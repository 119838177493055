import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingContainer = styled.div`
	text-align: center;
	position: relative;
	top: calc((100vh - 10vh - 20px - 240px) / 2);
	left: 0px;
	right: 0px;
	bottom: 0px;
`;

const LoadingComponent = () => {
	return (
		<LoadingContainer style={{ textAlign: "center" }}>
			<CircularProgress size={75} />
		</LoadingContainer>
	);
};
export default LoadingComponent;
