import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
	createMuiTheme,
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import Content from "./structure/Content";
import Footer from "./structure/Footer";
import Alert from "./components/Alert";
import ContentModal from "./components/ContentModal";
import "./global.css";

const theme = createMuiTheme({
	palette: {
		primary: { main: "#764899" },
		secondary: { main: "#D93994" },
		orange: { main: "#F2A524" },
		gray: {
			light: "#C6C6C6",
			dark: "#2F3D47",
		},
	},
	typography: {
		fontFamily: ["Helvetica Neue", "Poppins", "sans-serif"].join(","),
	},
	overrides: {
		MuiPaper: {
			root: {
				color: "#2F3D47",
			},
		},
	},
});

function App() {
	return (
		<MuiThemeProvider theme={theme}>
			<ThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<Alert />
					<ContentModal />
					<Router>
						<Content />
						<Footer />
					</Router>
				</StylesProvider>
			</ThemeProvider>
		</MuiThemeProvider>
	);
}

export default App;
