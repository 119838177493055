import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import chatReducer from "../reducers/chatReducer";
import qnaReducer from "../reducers/qnaReducer";
import voiceReducer from "../reducers/voiceReducer";
import uiReducer from "../reducers/uiReducer";

const rootReducer = combineReducers({
	chatReducer,
	qnaReducer,
	voiceReducer,
	uiReducer,
});

const middleware = [thunk];

export default () => {
	return createStore(
		rootReducer,
		composeWithDevTools(applyMiddleware(...middleware))
	);
};
