import React from "react";
import parse from "html-react-parser/index";
import { ReactComponent as Venn } from "../img/venndiagram.svg";

// Parse HTML from TCS for React components
export const parseHtml = (unparsedHtml, options = {}) => {
	if (options.removeOuterP) {
		return parse(unparsedHtml.replace(/(<p>)|(<\/p>)/g, ""));
	}
	if (options.removeAllP) {
		return unparsedHtml.replace(/(<p>)|(<\/p>)/g, "");
	}
	return parse(unparsedHtml);
};

// Determine which image to show in conversation modes
export const renderVisual = (imageURL, conversationFn) => {
	const parsedURL = imageURL ? parseHtml(imageURL, { removeAllP: true }) : null;
	if (parsedURL?.includes("venndiagram")) {
		return (
			<Venn
				onClick={(e) => {
					const attr = handleVennClick(e.target);
					conversationFn(attr);
				}}
				style={{ maxWidth: "100%" }}
				className="venn-diagram"
			/>
		);
	} else if (parsedURL) {
		return <img src={parsedURL} alt="message visual" />;
	} else {
		return "";
	}
};

// Determine which attribute to add after clicking Venn Diagram
export const handleVennClick = (clickedNode) => {
	const target = clickedNode.id ? clickedNode.id : clickedNode.href.baseVal;

	if (target === "blue-circle" || target.includes("text-3")) {
		return "Behavioral Science";
	} else if (target === "pink-circle" || target.includes("text-1")) {
		return "Storytelling";
	} else if (target === "purple-circle" || target.includes("text-5")) {
		return "Tailored Interaction Technology";
	} else if (target === "white-circle" || target.includes("Bitmap")) {
		return "Intersection";
	}
};
