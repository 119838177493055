import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { closeModal } from "../actions/uiActions";
import { parseHtml } from "./helpers";

const ModalComponent = styled(Modal)`
	outline: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Content = styled.div`
	background-color: white;
	width: 600px;
	max-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: 0;
`;

const ContentModal = () => {
	const dispatch = useDispatch();
	const uiState = useSelector((state) => state.uiReducer);

	const Video = () => {
		return (
			<video width="600" controls>
				<source
					src={parseHtml(uiState.modalContent, { removeAllP: true })}
					type="video/mp4"
				/>
			</video>
		);
	};

	return (
		uiState.modalOpen && (
			<div>
				<ModalComponent
					open={uiState.modalOpen}
					onClose={() => dispatch(closeModal())}
					aria-labelledby="pop-up"
					aria-describedby={uiState.modalDescription}
					BackdropComponent={Backdrop}
				>
					<Content>
						{uiState.isVideo && <Video />}
						{!uiState.isVideo && parseHtml(uiState.modalContent)}
					</Content>
				</ModalComponent>
			</div>
		)
	);
};

export default ContentModal;
