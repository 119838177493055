import React from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Home from "../Home";
import Voice from "../Voice";
import QnA from "../QnA";
import Chat from "../TextChat";
import ModeChoice from "../ModeChoice";
import Simulator from "../Simulator";
import Navigation from "../Navigation";
import TeamPage from "../TeamPage";

const MainContainer = styled.main`
	min-height: calc(100vh - 18vh - 20px - 40px - 4px);
	@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
		min-height: calc(100vh - 10vh - 20px - 40px);
	}
`;

const Content = () => {
	return (
		<MainContainer>
			<Switch>
				<Route path="/modeChoice">
					<ModeChoice />
				</Route>
				<Route path="/voice">
					<Voice />
				</Route>
				<Route path="/chat">
					<Chat />
				</Route>
				<Route path="/survey">
					<QnA />
				</Route>
				<Route path="/simulator/:id">
					<Simulator />
				</Route>
				<Route path="/info">
					<Navigation />
				</Route>
				<Route path="/team">
					<TeamPage />
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</MainContainer>
	);
};

export default Content;
