import {
	SHOW_SNACKBAR,
	CLOSE_SNACKBAR,
	GET_DATA,
	UI_ERROR,
	SHOW_MODAL,
	CLOSE_MODAL,
} from "./types";
import API from "../API";
import { messageMap } from "../messageMap";
import camelcaseKeys from "camelcase-keys";
import { sendError } from "./actionHelpers";

export const showAlert = (severity, message) => (dispatch) => {
	dispatch({
		type: SHOW_SNACKBAR,
		severity,
		message,
	});
};

export const closeAlert = () => (dispatch) => {
	dispatch({
		type: CLOSE_SNACKBAR,
	});
};

export const showModal = (modalContent, modalDescription, isVideo = false) => (
	dispatch
) => {
	dispatch({
		type: SHOW_MODAL,
		isVideo,
		modalContent,
		modalDescription,
	});
};

export const closeModal = () => (dispatch) => {
	dispatch({
		type: CLOSE_MODAL,
	});
};

export const getElements = (
	messageName,
	messageID = null,
	includeFormatting = true
) => async (dispatch) => {
	try {
		if (messageName && !messageID) messageID = messageMap[messageName];
		const res = await API(`/message/${messageID}`, "POST", {
			includeFormatting,
		});

		dispatch({
			type: GET_DATA,
			payload: camelcaseKeys(res),
		});
	} catch (err) {
		dispatch(sendError(err, UI_ERROR));
	}
};

export const getAttributes = (messageID) => async (dispatch) => {
	try {
		const res = await API("/prospectChat/text/", "POST", {
			currentMessageID: messageID,
		});

		const attributesToShow = res.messageAttributes[messageID].attributes.filter(
			(attribute) =>
				!attribute.attribute.name.includes("Prompt") &&
				!attribute.attribute.name.includes("Other Simulation") &&
				!attribute.attribute.name.includes("Raw")
		);

		dispatch({
			type: GET_DATA,
			payload: { attributes: attributesToShow },
		});
	} catch (err) {
		dispatch(sendError(err, UI_ERROR));
	}
};

export const getSimContent = (messageID, attributeData) => async (dispatch) => {
	try {
		const res = await API("/prospectChat/text/", "POST", {
			currentMessageID: messageID,
			userData: camelcaseKeys(attributeData),
		});

		let simContent = {};

		if (Number(messageID) === messageMap.cheeseTownSimulator) {
			const {
				background,
				body,
				bottomTag,
				callToAction,
				headline,
				hobby,
				cheese,
				state,
				topTag,
			} = res.messageAttributes[messageID];
			simContent = {
				cheeseTown: {
					background,
					body,
					bottomTag,
					callToAction,
					headline,
					hobby,
					cheese,
					state,
					topTag,
				},
			};
		} else if (Number(messageID) === messageMap.thanksgivingSimulator) {
			simContent = {
				thanksgiving: res.messageAttributes[messageID].thanksgivingSimulation,
			};
		} else if (Number(messageID) === messageMap.stressSimulator) {
			const {
				firstParagraph,
				secondParagraph,
				thirdParagraph,
			} = res.messageAttributes[messageID];
			simContent = {
				stress: { firstParagraph, secondParagraph, thirdParagraph },
			};
		}

		dispatch({
			type: GET_DATA,
			payload: simContent,
		});
	} catch (err) {
		dispatch(sendError(err, UI_ERROR));
	}
};
