export const messageMap = {
	home: 1297,
	modeChoice: 1298,
	modeInstructions: 1298,
	team: 1485,
	stressSimulator: 1737,
	cheeseTownSimulator: 1734,
	thanksgivingSimulator: 1739,
	callToAction: 1697,
};

export const navMenus = {
	aboutTuzag: {
		attributeName: ["Overview-tuzag", "Our team", "Meet Jaime", "Contact us"],
		menuName: ["About us", "Our team", "Meet Jaime", "Contact us"],
		prospectType: "Unknown",
	},
	ourMethodology: {
		attributeName: [
			"Our Methodology overview",
			"Computer Mediated Conversation",
			"1:1 Engagements",
			"Design Philosophy",
			"Development Process",
		],
		menuName: [
			"Overview",
			"Computer Mediated Conversation",
			"1:1 Engagements",
			"Design Philosophy",
			"Development Process",
		],
		prospectType: "unknown",
	},
	ourProducts: {
		attributeName: ["MyHealthyDay", "Health@Home", "tuzagInside", "tuzagTCS"],
		menuName: ["MyHealthyDay", "Health@Home", "tuzagInside", "tuzagTCS"],
		prospectType: "Unknown",
	},
	employer: {
		attributeName: [
			"Anxiety at work",
			"Available at all times",
			"Continued conversation",
			"Customizable additions",
			"Depression at work",
			"Easily add members",
			"Easily tailoring",
			"Employer responsibility",
			"Enhancing productivity",
			"Facilitating access to care",
			"Fluctuating moods at work",
			"Goal-setting based",
			"HR resources",
			"Improving morale",
			"Increased use of services",
			"Increasing engagement",
			"Integratable",
			"Jaime support",
			"Managing costs",
			"OCD at work",
			"Panic attacks",
			"Profit margin",
			"Prompted responses",
			"Reducing employee anxiety",
			"Small to large company inclusion",
			"Specific tailoring",
			"Supporting employee mental/behavioral health",
			"Up-front pricing",
			"Updates available",
			"Work focused",
			"Work vs life balance for employees",
			"Work-related stress",
		],
		menuName: [
			"Anxiety at Work",
			"Available Anytime",
			"Continued Conversation",
			"Customize Additions",
			"Depression at Work",
			"Easily Add Members",
			"Easy tailoring",
			"Employer Responsibility",
			"Enhance Productivity",
			"Increase Access to Care",
			"Fluctuating Moods at Work",
			"Goal-setting Based",
			"HR Resources",
			"Improve Team Morale",
			"Increase Use of Services",
			"Increase Engagement",
			"Integratable",
			"Jaime Support",
			"Cost Effective",
			"OCD at Work",
			"Panic Attacks",
			"Increase Profit Margins",
			"Prompted Responses",
			"Reduce Employee Anxiety",
			"Company Inclusion",
			"Specific Tailoring",
			"Support Employee Mental Health",
			"Up-front Pricing",
			"Updates Available",
			"Work Focused",
			"Work-Life Balance",
			"Manage Work Related Stress",
		],
		prospectType: "employer",
	},
	healthPlan: {
		attributeName: [
			"Accessible anywhere",
			"Adherence",
			"Admin console",
			"Admin oversight",
			"App accessibility",
			"Available at all times",
			"Conversation-based",
			"Customizable health plan language",
			"Customizable member settings",
			"Decreasing premiums",
			"Easily adjustable content",
			"Engagement",
			"Engagement prompts",
			"Gamification",
			"Goal-setting based",
			"Health literacy",
			"Increased program enrollment",
			"Increasing profit margin",
			"Jaime support for health plan",
			"Leaderboards",
			"Manageable dialogue",
			"Managing polychronic members",
			"Member check-ins",
			"Member communication",
			"Polychronic health management",
			"Progress overseen",
			"Specific tailoring",
			"Sustained member interaction",
			"Understandable content",
			"Utilization",
		],
		menuName: [
			"Accessible Anywhere",
			"Member Adherence",
			"Admin Console",
			"App Accessibility",
			"Available Anytime",
			"Conversation Based",
			"Customize Language",
			"Customize Member Settings",
			"Decrease Premiums",
			"Adjust Current Content",
			"Engagement",
			"Engagment Prompts",
			"Engagement through Game Science",
			"Focus on Goal Setting",
			"Health Literacy",
			"Increase Program Enrollment",
			"Increase Profit Margin",
			"Jaime Support",
			"Leaderboards Challenge",
			"Manage Jaime Dialogue",
			"Member Check-ins",
			"Member Communication",
			"Manage Polychronic Members",
			"View Member Progress",
			"Specific Tailoring",
			"Sustained Member Interaction",
			"Understandable Content",
			"Utilization",
		],
		prospectType: "healthPlan",
	},
	deliverySystem: {
		attributeName: [
			"Accessible anywhere",
			"Adherence",
			"Admin console",
			"Admin oversight",
			"Appropriate utilization",
			"Available at all times",
			"Chronic care management",
			"Communication",
			"Conversation focused",
			"Customizable health plan language",
			"Customizable member settings",
			"Customizable per member",
			"Decreasing premiums",
			"Easily adjustable content",
			"Easy accessibility",
			"Engagement",
			"Engagement prompts",
			"Engaging features to draw members (change language)",
			"Gamification",
			"Health literacy",
			"Hospital Readmission",
			"Increasing profit margin",
			"Jaime support for health plan",
			"Leaderboards",
			"Making value-based care work",
			"Manageable dialogue",
			"Member check-ins",
			"Member-driven",
			"Polychronic health management",
			"Progress overseen (change language)",
			"Rehabilitation management",
			"Remove or add content at any time (change language)",
			"Specific tailoring",
			"Sustained member interaction",
			"Understandable content",
		],
		menuName: [
			"Accessible Anywhere",
			"Adherence",
			"Admin Console",
			"Admin Oversight",
			"Appropriate Utilization",
			"Available at All Times",
			"Chronic Care Management",
			"Communication",
			"Conversation Focused",
			"Customizable Health Plan Language",
			"Customizable Member Settings",
			"Customizable per Member",
			"Decreasing Premiums",
			"Easily Adjustable content",
			"Easy Accessibility",
			"Engagement",
			"Engagement Prompts",
			"Attractive Features",
			"Gamification",
			"Health Literacy",
			"Hospital Readmission",
			"Increase Profit Margin",
			"Jaime support for Health Plan",
			"Leaderboards",
			"Effective Value-based Care",
			"Manageable dialogue",
			"Member Check-ins",
			"Member-driven",
			"Polychronic Health Management",
			"View Member Progress",
			"Rehabilitation Management",
			"Remove Unwanted Content",
			"Specific Tailoring",
			"Sustained Member Interaction",
			"Understandable Content",
		],
		prospectType: "deliverySystem",
	},
	broker: {
		attributeName: [
			"Access",
			"Accessible on personal devices",
			"Admin oversight",
			"Better profits",
			"Build-to-specs product",
			"Client comprehension",
			"Client retention",
			"Client satisfaction",
			"Competitive offers",
			"Current program integration",
			"Customer engagement",
			"Customizable language for consumers",
			"Differentiation",
			"Easy to gain members",
			"Employee engagement",
			"Engagement prompts",
			"Foresighted technology",
			"Fresh conversations",
			"Future program integration",
			"Gamification",
			"Goal-based",
			"High premiums",
			"Member needs accounted for",
			"Member satisfaction",
			"Prompted responses",
			"Research-based",
			"Specific client needs met",
			"Specific member needs met",
			"Specific tailoring",
			"Targeted member needs",
			"Where to start",
		],
		menuName: [
			"Access",
			"Accessible on Personal Devices",
			"Admin Oversight",
			"Better Profits",
			"Build-to-specs Product",
			"Client Comprehension",
			"Client Retention",
			"Client Satisfaction",
			"Competitive Offers",
			"Current Program Integration",
			"Customer Engagement",
			"Customizable Language for Consumers",
			"Differentiation",
			"Draw in Members",
			"Employee Engagement",
			"Engagement Prompts",
			"Foresighted Technology",
			"Fresh Conversations",
			"Future Program Integration",
			"Gamification",
			"Goal-based",
			"Lowering High Premiums",
			"Member Needs",
			"Member Satisfaction",
			"Prompted Responses",
			"Research-based",
			"Specific Client Needs",
			"Specific Member Needs",
			"Specific Tailoring",
			"Targeted Member Needs",
			"Where to Start",
		],
		prospectType: "broker",
	},
	partner: {
		attributeName: [
			"Access to questions about Jaime",
			"Access to tuzag support",
			"Adjustable goals",
			"Caregiver/Doctor prompts",
			"Challenges based on member needs",
			"Clinical content",
			"Customizable tasks",
			"Digital/Live Services integration",
			"Dormancy",
			"Easily marketable",
			"Engagement",
			"Extending reach",
			"Fixed pricing per API",
			"High efficacy",
			"Increase registration/conversion",
			"Integratable",
			"Interactive design",
			"Lack of incentives",
			"Leaderboard challenge",
			"Member content not shared",
			"Minimal adjustment needed for members",
			"Only stats shared on leaderboard",
			"Participant privacy/security",
			"Prompted responses",
			"Resistance to change",
			"Secure content",
			"Secure data",
			"Specific offers per member",
			"Staying current",
			"Task variation for members",
			"Teachable material",
			"Understandable content",
			"User-friendly",
			"Viewable progress",
		],
		menuName: [
			"Access to Questions about Jaime",
			"Access to tuzag Support",
			"Adjustable Goals",
			"Caregiver/Doctor Prompts",
			"Challenges Based on Member Needs",
			"Clinical Content",
			"Customizable Tasks",
			"Digital/Live Services Integration",
			"Dormancy",
			"Easily Marketable",
			"Engagement",
			"Extending Reach",
			"Fixed Pricing per API",
			"High Efficacy",
			"Increase Registration or Conversion",
			"Integratable",
			"Interactive Design",
			"Lack of Incentives",
			"Leaderboard Challenge",
			"Secure Member Content",
			"Minimal Adjustment for Members",
			"Leaderboard Stats",
			"Participant Privacy and Security",
			"Prompted Responses",
			"Resistance to Change",
			"Secure Content",
			"Secure Data",
			"Specific Offers",
			"Staying Current",
			"Task Variation",
			"Teachable Material",
			"Understandable Content",
			"User-friendly",
			"Viewable Progress",
		],
		prospectType: "partner",
	},
};
