import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import GeneralLayout from "./components/GeneralLayout";
import { getElements } from "./actions/uiActions";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { parseHtml } from "./components/helpers";
import LoadingComponent from "./components/LoadingComponent";

const Heading = styled.h1`
	${({ theme }) => `
		margin-bottom: 15px;
		color: ${theme.palette.primary.main};
		text-align: center;		
	`}
`;

const LargePic = styled.img`
	max-height: 250px;
	margin: 0 auto;
`;

const Content = styled.p`
	${({ theme }) => `
		text-align: left;
		& p {
			margin-bottom: 10px;
		}
		& a {
			color: ${theme.palette.secondary.main};
			&:hover {
			text-decoration: underline;
		}
		}
	`}
`;

const team = [
	"Dave Bulger",
	"Max Matthews",
	"Neal Sofian",
	"David Fine",
	"Jennifer Sisko",
	"Jason Baldwin",
	"Terry Egan",
	"Cynthia Gomez",
	"Nicole Miller",
	"Amanda Cox",
	"Ryan Lambacher",
	"Chris Romito",
];

const TeamPage = () => {
	const uiState = useSelector((state) => state.uiReducer);
	const dispatch = useDispatch();
	const [currentMember, setCurrentMember] = useState(null);
	const [bio, setBio] = useState(null);

	useEffect(() => {
		dispatch(getElements("team"));
	}, []);

	const getPhotos = () => {
		return Object.entries(uiState).map(([elementKey, elementValue]) => {
			if (elementKey.includes("Pic")) {
				const name = elementKey.slice(0, -3);
				return (
					<GridListTile>
						<img
							key={name}
							src={parseHtml(elementValue, { removeAllP: true })}
							alt={`${name}`}
							className={currentMember === name ? "selected" : ""}
							onClick={() => {
								setCurrentMember(name);
								setBio(parseHtml(uiState[`${name}Bio`]));
							}}
						/>
					</GridListTile>
				);
			}
		});
	};

	return (
		<Fragment>
			{!uiState.loading ? (
				<GeneralLayout
					pageTitle="The tuzag Team"
					content={
						<div style={{ textAlign: "center" }}>
							<LargePic
								src={
									uiState[`${currentMember}Pic`] &&
									parseHtml(uiState[`${currentMember}Pic`], {
										removeOuterP: true,
									})
								}
								alt={currentMember}
							/>
							<Heading>
								{team.find((name) =>
									name.toLowerCase().includes(currentMember)
								)}
							</Heading>
							<Content>{bio}</Content>
						</div>
					}
					visual={
						<GridList
							cellHeight={window.innerWidth >= 600 ? 130 : 160}
							cols={window.innerWidth >= 600 ? 3 : 2}
						>
							{getPhotos()}
						</GridList>
					}
					instructions={"Need instructions for this page to add here."}
				/>
			) : (
				<LoadingComponent />
			)}
		</Fragment>
	);
};

export default TeamPage;
