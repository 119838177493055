import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
	startQna,
	onSave,
	addAttribute,
	resetAttributes,
} from "./actions/qnaActions";
import ConversationLayout from "./components/ConversationLayout";
import { getElements, showAlert, showModal } from "./actions/uiActions";
import { OrangeButton } from "./components/Buttons";
import { parseHtml, renderVisual } from "./components/helpers";
import LoadingComponent from "./components/LoadingComponent";
import { messageMap } from "./messageMap";

const Heading = styled.h2`
	${({ theme }) => `
		font-size: 18px;
		margin-bottom: 15px;
		font-weight: 500;
		@media (min-width: 600px) {
			font-size: 22px;
		}
		& a {
			color: ${theme.palette.secondary.main};
		}
		& a:hover {
			text-decoration: underline;
		}
	`}
`;

const QnA = () => {
	const dispatch = useDispatch();
	const surveyState = useSelector((state) => state.qnaReducer);
	const [userResponse, setUserResponse] = useState("");
	const uiState = useSelector((state) => state.uiReducer);

	useEffect(() => {
		dispatch(resetAttributes());
		dispatch(
			addAttribute({
				explorationStyleList: "conversation",
				modeChoiceList: "q&a",
			})
		);
		dispatch(startQna());
		dispatch(getElements("modeChoice"));
	}, []);

	useEffect(() => {
		if (surveyState.error)
			dispatch(showAlert("error", surveyState.error.message));
	}, [surveyState]);

	useEffect(() => {
		if (surveyState.responseMessageID === messageMap.callToAction) {
			dispatch(
				showModal(
					surveyState.question
						.match(/(((https?:\/\/)|(www\.))[^\s]+)/g)
						.join(""),
					"A message from tuzag's CEO",
					true
				)
			);
		}
	}, [surveyState.question]);

	const handleAttr = (attribute) => {
		dispatch(
			addAttribute({
				aboutTuzagOverviewIntroductionList: attribute.toLowerCase(),
			})
		);
		dispatch(onSave(attribute));
	};

	return (
		<>
			{!uiState.loading ? (
				<ConversationLayout
					pageTitle="Survey Mode"
					content={
						<div>
							<Heading>
								{surveyState.question &&
									parseHtml(surveyState.question, { removeOuterP: true })}
							</Heading>
							<FormControl component="fieldset" fullWidth={true}>
								{surveyState.answerType === "list" && (
									<Select
										value={userResponse}
										onChange={(event) => setUserResponse(event.target.value)}
									>
										{surveyState.answers.map((answer, index) => (
											<MenuItem key={index} value={answer.label}>
												{answer.label}
											</MenuItem>
										))}
									</Select>
								)}
								{surveyState.answerType === "raw" && (
									<TextField
										required
										placeholder="Type something"
										value={userResponse}
										autoFocus={true}
										fullWidth={true}
										onChange={(e) => setUserResponse(e.target.value)}
									/>
								)}
							</FormControl>
							<OrangeButton
								variant="contained"
								onClick={() => {
									dispatch(onSave(userResponse));
									setUserResponse("");
								}}
							>
								Continue
							</OrangeButton>
						</div>
					}
					visual={
						surveyState.elementMap &&
						renderVisual(surveyState.elementMap.visual, handleAttr)
					}
					instructions={parseHtml(uiState.surveyModeInstructions)}
				/>
			) : (
				<LoadingComponent />
			)}
		</>
	);
};

export default QnA;
