import axios from "axios";

let apiURL = `http://localhost:1337`;

if (process.env.REACT_APP_ENV === "staging") {
	apiURL = "https://apistaging.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "prod") {
	apiURL = "https://api.tuzagtcs.com";
}

const API = async (url, method, data = {}, errorCatcher, formData) => {
	try {
		let response;
		let timezone;

		try {
			timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		} catch {
			//ignore
		}

		if (formData) {
			response = await axios({
				method,
				url: `${apiURL}${url}`,
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					apiKey: "Uc9zLKg6QvF8fJwwuhMsJrQvB2DMuTo4IVbJA5nE",
					timezone,
				},
			});
		} else {
			response = await axios({
				method,
				url: `${apiURL}${url}`,
				data: method === "GET" ? null : JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					apiKey: "Uc9zLKg6QvF8fJwwuhMsJrQvB2DMuTo4IVbJA5nE",
					timezone,
				},
			});
		}

		return response.data;
	} catch (e) {
		if (errorCatcher) errorCatcher(e);
		console.error(e);
		throw e;
	}
};

export default API;
