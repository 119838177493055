import {
	START_QNA,
	SEND_ANSWER,
	QNA_ERROR,
	ADD_ATTRIBUTE,
	RESET_ATTRIBUTES,
} from "../actions/types";

const initialState = {
	selectedAnswer: null,
	otherTextField: "",
	selectedAnswerDropdown: null,
	loading: true,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case START_QNA:
			return {
				...state,
				...payload,
				loading: false,
				newUserData: { ...state.newUserData, ...payload.newUserData },
				answerType: !payload.answers ? "raw" : "list",
				error: false,
			};
		case SEND_ANSWER:
			return {
				...state,
				...payload,
				loading: false,
				newUserData: { ...state.newUserData, ...payload.newUserData },
				answerType: !payload.answers ? "raw" : "list",
				responseMessageID: state.nextMessageId,
				error: false,
			};
		case ADD_ATTRIBUTE:
			return {
				...state,
				newUserData: {
					...state.newUserData,
					...payload,
				},
			};
		case RESET_ATTRIBUTES:
			return {
				...state,
				newUserData: {},
			};
		case QNA_ERROR:
			return {
				error: payload,
			};
		default:
			return state;
	}
};
