import {
	START_CHAT,
	GET_RESPONSE,
	SEND_MESSAGE,
	CHAT_ERROR,
	ADD_ATTRIBUTE,
} from "./types";
import API from "../API";
import { sendError } from "./actionHelpers";

export const startChat = () => async (dispatch, getState) => {
	const state = getState();
	const { newUserData } = state.chatReducer;

	try {
		let res = await API("/prospectChat/text/", "POST", {
			start: true,
			userData: newUserData,
			requestedElements: ["visual"],
			includeFormatting: true,
		});

		dispatch({
			type: START_CHAT,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, CHAT_ERROR));
	}
};

export const sendMessage = (messages) => (dispatch) => {
	return dispatch({
		type: SEND_MESSAGE,
		payload: messages,
	});
};

export const onSend = (messages) => async (dispatch, getState) => {
	try {
		dispatch(sendMessage(messages));

		const state = getState();
		const { nextMessageID, newUserData, prospectID } = state.chatReducer;

		let res = await API("/prospectChat/text/", "POST", {
			prospectID,
			currentMessageID: nextMessageID,
			message: messages[0].text,
			userData: newUserData,
			requestedElements: ["visual"],
			includeFormatting: true,
		});

		dispatch({
			type: GET_RESPONSE,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, CHAT_ERROR));
	}
};

export const addAttribute = (attributeObj) => async (dispatch) => {
	return dispatch({
		type: ADD_ATTRIBUTE,
		payload: attributeObj,
	});
};
