import {
	START_VOICE,
	VOICE_ERROR,
	VOICE_SEND,
	STOP_TALKING,
	ADD_ATTRIBUTE,
} from "../actions/types";

const initialState = {
	loading: true,
	talking: false,
	newUserData: {
		explorationStyleList: "conversation",
		modeChoiceList: "voice",
	},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case START_VOICE:
			return {
				...state,
				...payload,
				loading: false,
				talking: true,
				visual: payload.elementMap.visual,
				newUserData: { ...state.newUserData, ...payload.newUserData },
				prospectID: payload.prospectID,
			};
		case VOICE_SEND:
			return {
				...state,
				...payload,
				talking: true,
				visual: payload.elementMap.visual,
				newUserData: { ...state.newUserData, ...payload.newUserData },
				responseMessageID: state.nextMessageID,
			};
		case ADD_ATTRIBUTE:
			return {
				...state,
				newUserData: {
					...state.newUserData,
					...payload,
				},
			};
		case VOICE_ERROR:
			return {
				error: payload,
			};
		case STOP_TALKING:
			return {
				...state,
				talking: false,
			};
		default:
			return state;
	}
};
