import {
	START_CHAT,
	GET_RESPONSE,
	SEND_MESSAGE,
	CHAT_ERROR,
	ADD_ATTRIBUTE,
} from "../actions/types";
import shortid from "shortid";

const initialState = {
	messages: [],
	newUserData: {
		explorationStyleList: "conversation",
		modeChoiceList: "chat",
	},
	loading: true,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case START_CHAT:
			return {
				...state,
				...payload,
				messages: payload.messages
					.map((message, index) => {
						return {
							_id: shortid.generate(),
							createdAt: new Date(),
							text: message,
							user: {
								_id: 2,
								name: "TCS",
							},
							messageID: payload.nextMessageID,
						};
					})
					.reverse(),
				newUserData: { ...state.newUserData, ...payload.newUserData },
				loading: false,
				prospectID: payload.prospectID,
			};
		case SEND_MESSAGE:
			return {
				...state,
				messages: [...payload, ...state.messages],
			};
		case GET_RESPONSE:
			return {
				...state,
				...payload,
				messages: [
					...payload.messages
						.map((message, index) => {
							return {
								_id: shortid.generate(),
								createdAt: new Date(),
								text: message,
								user: {
									_id: 2,
									name: "TCS",
								},
								messageID:
									index === 0 ? state.nextMessageID : payload.nextMessageID,
							};
						})
						.reverse(),
					...state.messages,
				],
				newUserData: { ...state.newUserData, ...payload.newUserData },
				responseMessageID: state.nextMessageID,
				nextMessageID: payload.nextMessageID,
				loading: false,
			};
		case ADD_ATTRIBUTE:
			return {
				...state,
				newUserData: {
					...state.newUserData,
					...payload,
				},
			};
		case CHAT_ERROR:
			return {
				error: payload,
			};
		default:
			return state;
	}
};
