import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
	startQna,
	onSave,
	addAttribute,
	resetAttributes,
} from "./actions/qnaActions";
import { navMenus } from "./messageMap";
import camelcase from "camelcase";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { OrangeButton } from "./components/Buttons";
import { showAlert } from "./actions/uiActions";
import { parseHtml } from "./components/helpers";

const StyledTab = styled(Tab)`
	border-bottom: 1px solid lightgray;
	&:last-of-type {
		border-bottom: 0px;
	}
`;

const Content = styled.article`
	${({ theme }) => `
		border: 1px solid ${theme.palette.primary.main};
		padding: 15px 10px;
		min-height: 300px;
		@media (min-width: 600px) {
			min-height: 475px;
		}
	`}
`;

const Heading = styled.h1`
	${({ theme }) => `
		margin-bottom: 15px;
		color: ${theme.palette.secondary.main}
	`}
`;

const CustomBreadcrumbs = styled(Breadcrumbs)`
	${({ theme }) => `
		margin-top: -25px;
		& a {
			color: ${theme.palette.secondary.main};
		}
		.current {
			color: ${theme.palette.gray.dark};
		}
		& a:hover {
			text-decoration: underline;
		}
	`}
`;

const Navigation = () => {
	const surveyState = useSelector((state) => state.qnaReducer);
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState(0);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [selectedMenu, setSelectedMenu] = useState("aboutTuzag");
	const [userResponse, setUserResponse] = useState("");

	useEffect(() => {
		getInitialContent(0);
	}, []);

	useEffect(() => {
		if (surveyState.error)
			dispatch(showAlert("error", surveyState.error.message));
	}, [surveyState]);

	const handleMenuClick = (evt, menuName) => {
		setMenuAnchorEl(evt.currentTarget);
		setSelectedMenu(menuName);
	};

	const getInitialContent = (index) => {
		dispatch(resetAttributes());
		dispatch(
			addAttribute({
				prospectTypeList: camelcase(navMenus[selectedMenu].prospectType),
				currentTopicList: camelcase(
					navMenus[selectedMenu].attributeName[index]
				),
				explorationStyleList: "navigate",
			})
		);
		dispatch(startQna(navMenus[selectedMenu].menuName[index]));

		setMenuAnchorEl(null);
	};

	return (
		<Container maxWidth="lg" style={{ paddingTop: "40px" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<CustomBreadcrumbs aria-label="breadcrumb" separator="›">
						<Link to="/">Home</Link>
						<span className="current">Self-Guided Tour</span>
					</CustomBreadcrumbs>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Tabs
						orientation="vertical"
						variant="fullWidth"
						value={selectedTab}
						onChange={(event, value) => setSelectedTab(value)}
						aria-label="Navigation"
						textColor="primary"
						TabIndicatorProps={{
							style: {
								left: 0,
								backgroundColor: "#F2A524",
							},
						}}
					>
						<StyledTab
							label="About tuzag"
							onClick={(evt) => handleMenuClick(evt, "aboutTuzag")}
						/>
						<StyledTab
							label="Our Methodology"
							onClick={(evt) => handleMenuClick(evt, "ourMethodology")}
						/>
						<StyledTab
							label="Our Products"
							onClick={(evt) => handleMenuClick(evt, "ourProducts")}
						/>
						<StyledTab
							label="I am an employer..."
							onClick={(evt) => handleMenuClick(evt, "employer")}
						/>
						<StyledTab
							label="I work for a health plan..."
							onClick={(evt) => handleMenuClick(evt, "healthPlan")}
						/>
						<StyledTab
							label="I work for a delivery system..."
							onClick={(evt) => handleMenuClick(evt, "deliverySystem")}
						/>
						<StyledTab
							label="I am a broker..."
							onClick={(evt) => handleMenuClick(evt, "broker")}
						/>
						<StyledTab
							label="I might like to partner with tuzag..."
							onClick={(evt) => handleMenuClick(evt, "partner")}
						/>
						<Menu
							anchorEl={menuAnchorEl}
							open={Boolean(menuAnchorEl)}
							onClose={() => setMenuAnchorEl(null)}
							PaperProps={{
								style: {
									maxHeight: 48 * 4.5,
									width: "45ch",
								},
							}}
						>
							{navMenus[selectedMenu].menuName.map((option, index) => (
								<MenuItem
									key={index}
									selected={option === navMenus[selectedMenu].menuName[0]}
									onClick={() => getInitialContent(index)}
								>
									{option}
								</MenuItem>
							))}
						</Menu>
					</Tabs>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Content>
						<Heading>{surveyState.navTitle}</Heading>
						<p>{surveyState.question && parseHtml(surveyState.question)}</p>
						<FormControl component="fieldset" fullWidth={true}>
							{surveyState.answerType === "list" && (
								<Select
									value={userResponse}
									onChange={(event) => setUserResponse(event.target.value)}
								>
									{surveyState.answers.map((answer, index) => (
										<MenuItem key={index} value={answer.label}>
											{answer.label}
										</MenuItem>
									))}
								</Select>
							)}
							{surveyState.answerType === "raw" && (
								<TextField
									required
									placeholder="Type something"
									value={userResponse}
									autoFocus={true}
									fullWidth={true}
									onChange={(e) => setUserResponse(e.target.value)}
								/>
							)}
						</FormControl>
						<OrangeButton
							variant="contained"
							onClick={() => {
								dispatch(onSave(userResponse));
								setUserResponse("");
							}}
						>
							Continue
						</OrangeButton>
					</Content>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Navigation;
