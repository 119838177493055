import styled from "styled-components";
import { lighten, darken } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const OrangeButton = styled(Button)`
	${({ theme }) => `
	display: block;
  background-color: ${theme.palette.orange.main};
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  font-size: 14px;
  margin: 15px 0px;
  &:hover {
    background-color: ${lighten(theme.palette.orange.main, 0.2)};
  }
  `}
`;

const PurpleButton = styled(OrangeButton)`
	${({ theme }) => `
  background-color: ${theme.palette.primary.main};
  &:hover {
    background-color: ${darken(theme.palette.primary.main, 0.2)};
  }
  `}
`;

const PinkButton = styled(OrangeButton)`
	${({ theme }) => `
  background-color: ${theme.palette.secondary.main};
  &:hover {
    background-color: ${darken(theme.palette.secondary.main, 0.2)};
  }
  `}
`;

export { OrangeButton, PurpleButton, PinkButton };
