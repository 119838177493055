import {
	START_QNA,
	QNA_ERROR,
	SEND_ANSWER,
	ADD_ATTRIBUTE,
	RESET_ATTRIBUTES,
} from "./types";
import API from "../API";
import { sendError } from "./actionHelpers";

export const startQna = (navTitle = null) => async (dispatch, getState) => {
	try {
		const state = getState();
		const { newUserData } = state.qnaReducer;

		let res = await API("/prospectChat/qAndA/", "POST", {
			start: true,
			userData: newUserData,
			requestedElements: ["visual"],
			includeFormatting: true,
		});

		if (navTitle) res.navTitle = navTitle;

		dispatch({
			type: START_QNA,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, QNA_ERROR));
	}
};

export const onSave = (answer) => async (dispatch, getState) => {
	try {
		const state = getState();
		const { nextMessageId, newUserData, prospectID } = state.qnaReducer;

		let res = await API("/prospectChat/qAndA/save", "POST", {
			prospectID,
			currentMessageID: nextMessageId,
			answer: typeof answer === "number" ? answer.toString() : null,
			otherTextField: typeof answer === "string" ? answer : null,
			userData: newUserData,
			requestedElements: ["visual"],
			includeFormatting: true,
		});

		dispatch({
			type: SEND_ANSWER,
			payload: res,
		});
	} catch (err) {
		dispatch(sendError(err, QNA_ERROR));
	}
};

export const addAttribute = (attributeObj) => async (dispatch) => {
	return dispatch({
		type: ADD_ATTRIBUTE,
		payload: attributeObj,
	});
};

export const resetAttributes = () => (dispatch) => {
	return dispatch({
		type: RESET_ATTRIBUTES,
	});
};
