import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const LeftSide = styled.div`
	${({ theme }) => `
		border: 1px solid ${theme.palette.primary.main};
		padding: 15px 10px;
		min-height: 300px;
		@media (min-width: 600px) {
			min-height: 475px;
		}
	`}
`;

const RightSide = styled.div`
	text-align: center;
	& img:hover {
		cursor: pointer;
	}
	& .selected {
		opacity: 0.7;
	}
`;

const BackButton = styled.div`
	margin-top: -25px;
	padding-bottom: 10px;
`;

const InstructionText = styled.p`
	font-weight: 300;
	font-size: 16px;
	padding-bottom: 10px;
`;

const GeneralLayout = ({ content, visual, instructions, pageTitle }) => {
	return (
		<Container maxWidth="lg" style={{ paddingTop: "40px" }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<BackButton>
						<Link to="/">
							<Button
								variant="contained"
								color="secondary"
								startIcon={<ArrowBackIcon />}
								onClick={() => window.close()}
							>
								Back to Content
							</Button>
						</Link>
					</BackButton>
				</Grid>
				<Grid item xs={12}>
					<InstructionText>{instructions}</InstructionText>
				</Grid>
				<Grid item xs={12} md={6}>
					<LeftSide>{content}</LeftSide>
				</Grid>
				<Grid item xs={12} md={6}>
					<RightSide>{visual}</RightSide>
				</Grid>
			</Grid>
		</Container>
	);
};

export default GeneralLayout;
