import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import logoColor from "./img/tuzagLogoColor.svg";
import { OrangeButton } from "./components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getElements } from "./actions/uiActions";
import LoadingComponent from "./components/LoadingComponent";
import { parseHtml } from "./components/helpers";

const Section = styled.article`
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	&:nth-child(1) {
		padding-top: 30px;
	}
	& p {
		font-weight: 300;
	}
`;

const MediumHeading = styled.h2`
	${({ theme }) => `
		font-size: 22px;
		color: ${theme.palette.secondary.main};
		margin-bottom: 3px;
	`}
`;
const CtaOption = styled.div`
	${({ theme }) => `
		padding: 20px 0px 10px;
		@media (min-width: 960px) {
			padding: 20px 10px 10px 10px;
		}
		& h4 {
			width: 100%;
			text-align: center;
			border-bottom: 1px solid ${theme.palette.secondary.main};
			line-height: 0.1em;
			margin: 10px 0 0;
			& span {
				color: ${theme.palette.primary.main};
				font-weight: 500;
				font-size: 20px;
				padding: 0 10px;
				background-color: white;
			}
		}
		& .ctaContent {
			font-size: 16px;
			text-align: justify;
			font-weight: 300;
			border-left: 1px solid ${theme.palette.secondary.main};
			border-right: 1px solid ${theme.palette.secondary.main};
			border-bottom: 1px solid ${theme.palette.secondary.main};
			padding: 15px 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@media (min-width: 960px) {
				height: 130px;
			}
		}
	`}
`;

const CustomParagraph = styled.div`
	font-size: 20px;
	& p {
		margin-bottom: 20px;
	}
`;

const Home = () => {
	const uiState = useSelector((state) => state.uiReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getElements("home"));
	}, []);

	return (
		<Fragment>
			{!uiState.loading && uiState.missionAndVision ? (
				<Container maxWidth="md">
					<Section>
						<img src={logoColor} alt="tuzag logo" style={{ height: "175px" }} />
					</Section>
					<Section>
						<CustomParagraph>
							{parseHtml(uiState.missionAndVision)}
						</CustomParagraph>
					</Section>
					<Section>
						<MediumHeading>
							{parseHtml(uiState.titleForLearnMore, { removeOuterP: true })}
						</MediumHeading>
						<p>{parseHtml(uiState.learnMoreCopy, { removeOuterP: true })}</p>
						<Grid container>
							<Grid item xs={12} sm={12} md={6}>
								<CtaOption>
									<h4>
										<span>
											{parseHtml(uiState.titleForInteractive, {
												removeOuterP: true,
											})}
										</span>
									</h4>
									<div className="ctaContent">
										{parseHtml(uiState.copyForInteractive)}
										<Link to="/modeChoice">
											<OrangeButton>
												{parseHtml(uiState.buttonLabel, { removeOuterP: true })}
											</OrangeButton>
										</Link>
									</div>
								</CtaOption>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<CtaOption>
									<h4>
										<span>
											{parseHtml(uiState.titleForSelfguided, {
												removeOuterP: true,
											})}
										</span>
									</h4>
									<div className="ctaContent">
										{parseHtml(uiState.copyForSelfguided)}
										<Link to="/info">
											<OrangeButton>
												{parseHtml(uiState.buttonLabel, { removeOuterP: true })}
											</OrangeButton>
										</Link>
									</div>
								</CtaOption>
							</Grid>
						</Grid>
					</Section>
				</Container>
			) : (
				<LoadingComponent />
			)}
		</Fragment>
	);
};

export default Home;
