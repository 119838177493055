import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const ConversationModule = styled.div`
	${({ theme }) => `
		border: 1px solid ${theme.palette.primary.main};
		padding: 15px 10px;
		min-height: 300px;
		@media (min-width: 600px) {
			min-height: 475px;
		}
	`}
`;

const VisualModule = styled.div`
	text-align: center;
	& img {
		width: 100%;
		@media (max-width: 959px) {
			width: auto;
			height: 200px;
		}
	}
`;

const CustomBreadcrumbs = styled(Breadcrumbs)`
	${({ theme }) => `
		margin-top: -25px;
		padding-bottom: 10px;
		& a {
			color: ${theme.palette.secondary.main};
		}
		.current {
			color: ${theme.palette.gray.dark};
		}
		& a:hover {
			text-decoration: underline;
		}
	`}
`;

const InstructionText = styled.p`
	font-weight: 300;
	font-size: 16px;
	padding-bottom: 10px;
`;

const ConversationLayout = ({ content, visual, instructions, pageTitle }) => {
	return (
		<Container maxWidth="lg" style={{ paddingTop: "40px" }}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={8}>
					<CustomBreadcrumbs aria-label="breadcrumb" separator="›">
						<Link to="/">Home</Link>
						<Link to="/modeChoice">Mode Selection</Link>
						<span className="current">{pageTitle}</span>
					</CustomBreadcrumbs>
					<InstructionText>{instructions}</InstructionText>
					<ConversationModule>{content}</ConversationModule>
				</Grid>
				<Grid item xs={12} md={4}>
					<VisualModule>{visual}</VisualModule>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ConversationLayout;
